import React from "react";
import { useTranslation } from "react-i18next";
import Style from "./VatAlert.module.scss";

const VatAlert = () => {
  const { t } = useTranslation(["common"]);

  return (
    <div className={Style.order_summary}>
      <div className={Style.order_summary_title_sec}>
        <span className={Style.order_summary_icon}></span>
        <div className={`h5 ${Style.summary_title}`}> {t("Alert_Title")}!</div>
      </div>
      <div>
        <span>{t("Alert_Content")}</span>
      </div>
    </div>
  );
};

export default VatAlert;
