import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  paymentStatusData: null,
};

export const getPaymentStatus = createAsyncThunk(
  "paymentStatus/getPaymentStatus",
  async (params, { rejectWithValue }) => {
    let data = {
      token: localStorage.getItem("authToken"),
    };
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`payment-status/${params}`, data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const paymentStatusSlice = createSlice({
  name: "paymentStatus",
  initialState,
  reducers: {},
  extraReducers: {
    [getPaymentStatus.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getPaymentStatus.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.paymentStatusData = action.payload?.data?.data;
    },
    [getPaymentStatus.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default paymentStatusSlice.reducer;
