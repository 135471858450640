import axios from "axios";

const PRODUCTION = process.env.REACT_APP_PRODUCTION;
const API_TOKEN = process.env.REACT_APP_API_TOKEN;

const BASE_URL =
  PRODUCTION === "true"
    ? process.env.REACT_APP_APIURL_LIVE
    : process.env.REACT_APP_STAGING_APIURL;

export const getAxiosInstance = async () => {
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${API_TOKEN}`,
    },
  });
  instance.interceptors.request.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return instance;
};
