import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useListAmount } from "../../../logic/useListAmount";
import Style from "./GiftCardsValue.module.scss";
import { useTranslation } from "react-i18next";

const GiftCardsValue = ({ formik }) => {
  const { listAmountData } = useListAmount();
  const [inputValue, setInputValue] = useState("");
  const [inputActive, setInputActive] = useState("");

  const inputValueClick = (e) => {
    setInputValue(e.target.value);
    setInputActive(e.target.value);
  };

  const { t } = useTranslation(["common"]);
  const strippedString = listAmountData?.max_limit?.split(".")?.[0];
  const length = strippedString?.length;
  return (
    <section className={Style.gift_cards_value}>
      <div className="container">
        <div className={`sm_container ${Style.wrapper}`}>
          <div className="text-md-center">
            <h1 className="h6">{t("CHOOSE_GIFT_CARD")}</h1>
          </div>
          <div className={`row ${Style.custom_row}`}>
            <div className="col-md-6">
              <label htmlFor="" className={`h6 ${Style.title_label}`}>
                {t("Select_amount")}
              </label>
              <div className={Style.select_amount}>
                {listAmountData?.amounts?.map((value, index) => {
                  return (
                    <button
                      type="button"
                      className={
                        inputActive === value.amount
                          ? `custom_btn custom_btn-secondary ${Style.select_amount_btn} ${Style.active}`
                          : `custom_btn custom_btn-secondary ${Style.select_amount_btn} ${
                              formik.values.amount == value.amount && Style.active
                            }`
                      }
                      value={value.amount}
                      onClick={(e) => {
                        if (
                          e.target.value >= value?.min_limit &&
                          e.target.value <= value?.max_limit
                        ) {
                          formik.setFieldValue("amount", parseInt(value.amount));
                          inputValueClick(e);
                        } else if (
                          e.target.value > value?.max_limit ||
                          e.target.value < value?.min_limit
                        ) {
                          formik.setFieldValue("amount", parseInt(value.amount));
                          inputValueClick(e);
                        } else {
                          formik.setFieldValue("amount", parseInt(value.amount));
                          inputValueClick(e);
                        }
                      }}
                      key={index}
                    >
                      {parseInt(value.amount)}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="amount" className={`h6 ${Style.title_label}`}>
                {t("enter_amount")}
              </label>
              <Form.Group className="form-group text-start text-black" controlId="amount">
                <Form.Control
                  type="text"
                  name="amount"
                  value={parseInt(formik.values.amount) || inputValue}
                  maxLength={length}
                  placeholder={t("Enter_Amount")}
                  onBlur={formik.handleBlur}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setInputActive(e.target.value);
                    setInputValue(e.target.value);
                  }}
                />
              </Form.Group>
              {formik.touched.amount && formik.errors.amount ? (
                <div className="formikError">{formik.errors.amount}</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GiftCardsValue;
