import React, { useState, useEffect } from "react";
import moment from "moment";
import Style from "./GiftCardsDelivery.module.scss";
import { Form, Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { useTranslation } from "react-i18next";

const GiftCardsDelivery = ({ formik, loader, responseError }) => {
  const [showDate, setShowDate] = useState(false);
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    if (formik.values.type === 2) {
      setShowDate(true);
    }
  }, []);

  return (
    <section className={Style.gift_cards_delivery}>
      <div className="container">
        <div className={`sm_container ${Style.wrapper}`}>
          <div className="text-md-center">
            <h1 className="h6">{t("DELIVERY_DATE")}</h1>
          </div>
          <div className={Style.field_group}>
            <Form.Check
              type="radio"
              name="type"
              defaultChecked={formik.values.type === 1 ? true : false}
              id="send_now"
              label={t("Send_now")}
              onClick={() => {
                setShowDate(false);
                formik.setFieldValue("type", 1);
              }}
              onBlur={formik.handleBlur}
            />
            <Form.Check
              type="radio"
              name="type"
              defaultChecked={formik.values.type === 2 ? true : false}
              id="set_date"
              label={t("date_to_send")}
              onClick={() => {
                setShowDate(true);
                formik.setFieldValue("type", 2);
              }}
              onBlur={formik.handleBlur}
            />

            {formik.touched.type && formik.errors.type ? (
              <div className="formikError">{formik.errors.type}</div>
            ) : null}

            {showDate && (
              <Form.Group className="form-group text-start" controlId="date">
                <Form.Label>{t("date")}</Form.Label>
                <DatePicker
                  onChange={(e) => formik.setFieldValue("date", e)}
                  value={formik.values.date}
                  name="date"
                  minDate={moment().add(1, "d").toDate()}
                  format="dd/MM/yyyy"
                  clearIcon={null}
                  calendarIcon={
                    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        clipRule="evenodd"
                        d="M4 6V4H2v3h12V4h-2v2h-2V4H6v2H4Zm10 3H2v5h12V9ZM2 2h2V0h2v2h4V0h2v2h4v14H0V2h2Z"
                      />
                    </svg>
                  }
                />
              </Form.Group>
            )}
          </div>

          <button
            // variant="primary"
            type="submit"
            className={
              loader
                ? ` ${Style.add_cart_btn} loading custom_btn custom_btn-primary`
                : `${Style.add_cart_btn} custom_btn custom_btn-primary`
            }
          >
            {t("PROCEED_TO_CHECKOUT")}
          </button>

          {/* {responseError && (
            <p className={Style.submit_error}>{t("mobile_error")}</p>
          )} */}
        </div>
      </div>
    </section>
  );
};

export default GiftCardsDelivery;
