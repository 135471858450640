import React from "react";
import { useTranslation } from "react-i18next";
import Style from "./OrderSummary.module.scss";

const OrderSummary = ({ data }) => {
  const { t } = useTranslation(["common"]);

  return (
    <div className={Style.order_summary}>
      <div className={`h5 ${Style.summary_title}`}>{t("Order_Summary")}</div>
      <div className={Style.summary}>
        <div className={Style.summary_data}>
          <div className={Style.summary_data_key}>{data?.gift_card?.name}</div>
          <div className={Style.summary_data_value}>
            <sup>{t("QR")}</sup> {parseInt(data?.amount)}
          </div>
        </div>
        {/* <div className={Style.summary_data}>
          <div className={Style.summary_data_key}>{t("VAT")}</div>
        </div> */}
        <div className={Style.summary_total}>
          <div className={Style.summary_total_key}>{t("TOTAL")}</div>
          <div className={Style.summary_total_value}>
            <sup>{t("QR")}</sup> {parseInt(data?.amount)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
