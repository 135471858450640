import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuthToken } from "../store/slices/authTokenSlice";

export const useToken = () => {
  const dispatch = useDispatch();

  const getTokenFromApi = () => {
    dispatch(getAuthToken());
  };

  return {
    getTokenFromApi,
  };
};
